import {
  DeletePlug,
  addPlug,
  updatePlug,
} from "controller/services/plugServices";
import React, { useContext } from "react";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { AlertContext } from "controller/context/alertContext";
import { formatImage } from "controller/common/formatImage";
import { useOnePlug, usePlugType } from "model/queryCalls/queryCalls";

export const usePlug = () => {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [brandImage, setBrandImage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const fileInputRef = React.useRef(null); // Use ref for file input
  const [isActive, setIsActive] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { refetch } = usePlugType({ page, rowsPerPage });
  const [type, setType] = React.useState("AC");
  const [isVisible, setIsVisible] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState();

  const { data: plugData, isSuccess } = useOnePlug({ id });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const initialValues: any = {
    name: "",
    image: "",
    order: 1,
    powerOutput: 1,
    isActive: true,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    // image: Yup.mixed().required("Please upload an Image"),
    order: Yup.number().required("Please add order"),
    powerOutput: Yup.number().required("Please add power output"),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const params = {
        values,
        isActive,
        image: brandImage
          ? formatImage({ file: brandImage, source: "imageKit" })
          : null,
        type,
      };
      if (id) {
        await updatePlug({ id, ...params });
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Plug Updated successfully",
        });
      } else {
        await addPlug(params);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Plug added successfully",
        });
      }

      setTimeout(() => {
        navigate("/admin/all-plugs");
      }, 2000);
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: " Error while adding Plug",
      });
    }
  };

  const onError = () => {
    setAlert({
      active: true,
      type: "error",
      message: " Error while uploading image",
    });
    setIsUploading(false);
  };
  const onSuccess = (res: any) => {
    setBrandImage(res);
    setIsUploading(false);
  };
  const onUploadProgress = () => {
    setIsUploading(true);
  };

  const handleDeleteFile = () => {
    setBrandImage(null);
  };

  const onDelete = async (deleteId: any) => {
    try {
      await DeletePlug({ id: deleteId });
      refetch();
      setAlert({
        active: true,
        type: "success",
        message: "Successfully Deleted",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "success",
        message: "Failed to delete",
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType((event.target as HTMLInputElement).value);
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
    onError,
    onSuccess,
    onUploadProgress,
    isUploading,
    brandImage,
    loading,
    setLoading,
    fileInputRef,
    isActive,
    setIsActive,
    handleDeleteFile,
    setAlert,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    id,
    isSuccess,
    plugData,
    setBrandImage,
    onDelete,
    type,
    setType,
    handleChange,
    deleteId,
    setDeleteId,
    isVisible,
    setIsVisible,
  };
};
