import { Box, Skeleton } from "@mui/material";
import Card from "components/card";
import { useOneChargeHistory } from "model/queryCalls/queryCalls";

import { BsDatabaseFillX } from "react-icons/bs";
import { MdArrowCircleLeft } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";

const ChargingHistoryDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: historyData, status } = useOneChargeHistory({ id });

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <Card extra={"w-full h-full p-3"}>
          {status === "loading" ? (
            <Box>
              <Skeleton sx={{ height: 190 }} />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          ) : status === "success" ? (
            <div className="relative mb-3 items-center justify-between py-5 text-center lg:px-10">
              <div className="mb-auto flex flex-col items-center justify-center">
                <div className="flex w-full flex-row items-center justify-between">
                  <MdArrowCircleLeft
                    className="h-8 w-8 text-navy-700 hover:cursor-pointer hover:text-navy-500 dark:text-white hover:dark:text-gray-600"
                    onClick={() => navigate(-1)}
                  />
                  {/* <MdEditSquare
                    className="h-8 w-8 text-navy-700 hover:cursor-pointer hover:text-navy-500 dark:text-white hover:dark:text-gray-600"
                    onClick={() =>
                      navigate(`/admin/edit-station/${historyData._id}`)
                    }
                  /> */}
                </div>
              </div>
              <Card extra={"w-full h-full p-3 mt-6"}>
                {/* Cards */}
                <div className="mt-3 grid grid-cols-1 gap-4 px-2 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
                  <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 hover:cursor-pointer hover:bg-gray-100 dark:!bg-navy-700 dark:shadow-none"
                    onClick={() =>
                      navigate(
                        `/admin/customer-details/${historyData?.customerData._id}`
                      )
                    }>
                    <p className="text-sm text-gray-600">Customer</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {historyData?.customerData?.name}
                    </p>
                  </div>

                  <div
                    className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 hover:cursor-pointer hover:bg-gray-100 dark:!bg-navy-700 dark:shadow-none"
                    onClick={() =>
                      navigate(
                        `/admin/station-details/${historyData?.stationData._id}`
                      )
                    }
                  >
                    <p className="text-sm text-gray-600">Station</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {!historyData?.stationData?.name
                        ? `Not Available`
                        : historyData?.stationData?.name}
                    </p>
                  </div>
                  <div
                    className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 hover:cursor-pointer hover:bg-gray-100 dark:!bg-navy-700 dark:shadow-none"
                    onClick={() =>
                      navigate(
                        `/admin/vehicle-details/${historyData?.vehicleData._id}`
                      )
                    }
                  >
                    <p className="text-sm text-gray-600">Vehicle</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {historyData?.vehicleData?.name?.en}
                    </p>
                  </div>
                  <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p className="text-sm text-gray-600">Able to Charge</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {historyData?.ableToCharge ? `Charged` : `Not Charged`}
                    </p>
                  </div>

                  <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p className="text-sm text-gray-600">Status</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {!historyData.status
                        ? `Not Available`
                        : historyData.status}
                    </p>
                  </div>

                  <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p className="text-sm text-gray-600">Comment</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {historyData?.comment}
                    </p>
                  </div>

                  <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p className="text-sm text-gray-600">Check in Time</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {!historyData?.checkedInAt
                        ? `Not Available`
                        : historyData?.checkedInAt.toISOString()}
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          ) : (
            <div className="flex w-full flex-col items-center justify-center">
              <BsDatabaseFillX className="h-16 w-16 text-gray-500" />
              <p className="mt-3 text-sm font-bold text-gray-800">No Data</p>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default ChargingHistoryDetails;
