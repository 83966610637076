import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import Brands from "views/admin/brand";
import AddBrand from "views/admin/brand/addBrand";
import Vehicles from "views/admin/vehicle";
import AddVehicle from "views/admin/vehicle/addVehicle";
import Plugs from "views/admin/plugType";
import AddPlug from "views/admin/plugType/addPlug";
import MaintenanceMode from "views/admin/maintenance";
import ChargingStations from "views/admin/chargingStations";
import AddStation from "views/admin/chargingStations/addStation";
import StationDetails from "views/admin/chargingStations/stationDetails";
import Amenities from "views/admin/amenities";
import AddAmenity from "views/admin/amenities/addAmenity";
import FAQs from "views/admin/FAQ";
import AddFAQ from "views/admin/FAQ/addFAQ";
import VehicleDetails from "views/admin/vehicle/vehicleDetails";
import PlugDetails from "views/admin/plugType/plugDetails";
import BrandDetails from "views/admin/brand/brandDetails";
import AmenityDetails from "views/admin/amenities/amenityDetails";
import FAQDetails from "views/admin/FAQ/FAQDetails";
import Users from "views/admin/users";
import AddUsers from "views/admin/users/addUsers";
import CMS from "views/admin/CMS";
import AddCMS from "views/admin/CMS/addCMS";
import CMSDetails from "views/admin/CMS/CMSDetails";
import PopularRoutes from "views/admin/popularRoutes";
import AddRoute from "views/admin/popularRoutes/addRoute";
import RouteDetails from "views/admin/popularRoutes/RouteDetails";
import ComingSoon from "components/comingSoon";
import AddRoles from "views/admin/roles/addRoles";

// Auth Imports
import SignIn from "views/auth/SignIn";
import TwoStepAuth from "views/auth/TwoStepAuth";
import SignUp from "views/auth/SignUp";
import ForgotPassword from "views/auth/ForgotPassword";
import ConformPassword from "views/auth/ConfirmPassword";
import ChargingHistoryDetails from "views/admin/chargingHistory/historyDetails";
import ChargingHistory from "views/admin/chargingHistory";
import Customers from "views/admin/customers";
import CustomerDetails from "views/admin/customers/customerDetails";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    component: <MainDashboard />,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    component: <Profile />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    component: <SignIn />,
  },
  {
    name: "Two Step Auth",
    layout: "/auth",
    path: "two-step-auth",
    component: <TwoStepAuth />,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "forgot-password",
    component: <ForgotPassword />,
  },
  {
    name: "Conform Password",
    layout: "/auth",
    path: "conform-password",
    component: <ConformPassword />,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign-up",
    component: <SignUp />,
  },
  {
    name: "All Brands",
    layout: "/admin",
    path: "all-brands",
    component: <Brands />,
  },
  {
    name: "Add Brand",
    layout: "/admin",
    path: "add-brand",
    component: <AddBrand />,
  },
  {
    name: "Edit Brand",
    layout: "/admin",
    path: "edit-brand/:id",
    component: <AddBrand />,
  },
  {
    name: "Brand Details",
    layout: "/admin",
    path: "brand-details/:id",
    component: <BrandDetails />,
  },
  {
    name: "All Vehicles",
    layout: "/admin",
    path: "all-vehicles",
    component: <Vehicles />,
  },
  {
    name: "Add Vehicle",
    layout: "/admin",
    path: "add-vehicle",
    component: <AddVehicle />,
  },
  {
    name: "Edit Vehicle",
    layout: "/admin",
    path: "edit-vehicle/:id",
    component: <AddVehicle />,
  },
  {
    name: "Vehicle Details",
    layout: "/admin",
    path: "vehicle-details/:id",
    component: <VehicleDetails />,
  },
  {
    name: "All Plugs",
    layout: "/admin",
    path: "all-plugs",
    component: <Plugs />,
  },
  {
    name: "Add Plug",
    layout: "/admin",
    path: "add-plug",
    component: <AddPlug />,
  },
  {
    name: "Edit Plug",
    layout: "/admin",
    path: "edit-plug/:id",
    component: <AddPlug />,
  },
  {
    name: "Plug Details",
    layout: "/admin",
    path: "plug-details/:id",
    component: <PlugDetails />,
  },
  {
    name: "All Amenities",
    layout: "/admin",
    path: "all-amenities",
    component: <Amenities />,
  },
  {
    name: "Add Amenity",
    layout: "/admin",
    path: "add-amenity",
    component: <AddAmenity />,
  },
  {
    name: "Edit Amenity",
    layout: "/admin",
    path: "edit-amenity/:id",
    component: <AddAmenity />,
  },
  {
    name: "Amenity Details",
    layout: "/admin",
    path: "amenity-details/:id",
    component: <AmenityDetails />,
  },
  {
    name: "All FAQs",
    layout: "/admin",
    path: "all-faqs",
    component: <FAQs />,
  },
  {
    name: "Add FAQ",
    layout: "/admin",
    path: "add-faq",
    component: <AddFAQ />,
  },
  {
    name: "Edit FAQ",
    layout: "/admin",
    path: "edit-faq/:id",
    component: <AddFAQ />,
  },
  {
    name: "FAQ Details",
    layout: "/admin",
    path: "faq-details/:id",
    component: <FAQDetails />,
  },
  {
    name: "All CMS Pages",
    layout: "/admin",
    path: "all-cms",
    component: <CMS />,
  },
  {
    name: "Add CMS Page",
    layout: "/admin",
    path: "add-cms",
    component: <AddCMS />,
  },
  {
    name: "Edit CMS Page",
    layout: "/admin",
    path: "edit-cms/:id",
    component: <AddCMS />,
  },
  {
    name: "CMS Details",
    layout: "/admin",
    path: "cms-details/:id",
    component: <CMSDetails />,
  },
  {
    name: "All Popular Routes",
    layout: "/admin",
    path: "popular-routes",
    component: <PopularRoutes />,
  },
  {
    name: "Add Popular Route",
    layout: "/admin",
    path: "add-popular-route",
    component: <AddRoute />,
  },
  {
    name: "Edit Route",
    layout: "/admin",
    path: "edit-popular-route/:id",
    component: <AddRoute />,
  },
  {
    name: "Route Details",
    layout: "/admin",
    path: "popular-route-details/:id",
    component: <RouteDetails />,
  },
  {
    name: "All Charging Stations",
    layout: "/admin",
    path: "all-stations",
    component: <ChargingStations />,
  },
  {
    name: "Add Charging Station",
    layout: "/admin",
    path: "add-station",
    component: <AddStation />,
  },
  {
    name: "Edit Station",
    layout: "/admin",
    path: "edit-station/:id",
    component: <AddStation />,
  },
  {
    name: "Charging History",
    layout: "/admin",
    path: "charging-history",
    component: <ChargingHistory stationId={null} customerId={null} />,
  },
  {
    name: "Charging History",
    layout: "/admin",
    path: "charging-history-details/:id",
    component: <ChargingHistoryDetails />,
  },
  {
    name: "Station Details",
    layout: "/admin",
    path: "station-details/:id",
    component: <StationDetails />,
  },
  {
    name: "Maintenance Mode",
    layout: "/admin",
    path: "maintenance-mode",
    component: <MaintenanceMode />,
  },

  {
    name: "All Users",
    layout: "/admin",
    path: "all-users",
    component: <Users />,
  },
  {
    name: "Add Users",
    layout: "/admin",
    path: "add-users",
    component: <AddUsers />,
  },
  {
    name: "Edit Users",
    layout: "/admin",
    path: "edit-users/:id",
    component: <AddUsers />,
  },
  {
    name: "All Customers",
    layout: "/admin",
    path: "all-customers",
    component: <Customers />,
  },
  {
    name: "Customer Details",
    layout: "/admin",
    path: "customer-details/:id",
    component: <CustomerDetails />,
  },
  {
    name: "Add Roles",
    layout: "/admin",
    path: "add-roles",
    component: <AddRoles />,
  },
  {
    name: "Coming Soon",
    layout: "/admin",
    path: "coming-soon",
    component: <ComingSoon />,
  },
];
export default routes;
