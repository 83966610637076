import {
  DeleteVehicle,
  addVehicle,
  updateVehicle,
} from "controller/services/vehicleServices";
import React, { useContext } from "react";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { AlertContext } from "controller/context/alertContext";
import { formatImage } from "controller/common/formatImage";
import {
  useBrand,
  useOneVehicle,
  usePlugType,
  useVehicle,
} from "model/queryCalls/queryCalls";

export const useVehicles = () => {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [brandImage, setBrandImage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const fileInputRef = React.useRef(null); // Use ref for file input
  const [isActive, setIsActive] = React.useState(true);
  const [selectedPlugs, setSelectedPlugs] = React.useState<string[]>([]);
  const [selectedBrand, setSelectedBrand] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedPlugsData, setSelectedPlugsData] = React.useState([]);
  const [selectedBrandData, setSelectedBrandData] = React.useState();
  const [plugOptions, setPlugOptions] = React.useState([]);
  const [brandOptions, setBrandOptions] = React.useState([]);
  const [isVisible, setIsVisible] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState();

  const { data: plugsData } = usePlugType({ page: 0, rowsPerPage: 1000 });
  const { data: brandsData } = useBrand({ page: 0, rowsPerPage: 1000 });
  const { data: vehicleData, isSuccess } = useOneVehicle({ id });
  const { refetch } = useVehicle({ page, rowsPerPage });

  React.useEffect(() => {
    const brandOption =
      brandsData &&
      brandsData?.data?.map((item: any) => ({
        value: item._id,
        label: item.name.en,
      }));

    setBrandOptions(brandOption);
    setSelectedBrandData(
      brandOption &&
        brandOption.find(
          (option: any) =>
            option.value.toString() === vehicleData?.brand.toString()
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsData, vehicleData]);

  React.useEffect(() => {
    const plugOption =
      plugsData &&
      plugsData?.data?.map((item: any) => ({
        value: item._id,
        label: item.name.en,
      }));

    setPlugOptions(plugOption);
    setSelectedPlugsData(
      plugOption &&
        plugOption.filter((plug: any) =>
          vehicleData?.plugs.toString().includes(plug.value.toString())
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plugsData, vehicleData]);

  const onChange = (selectedOptions: any) => {
    setSelectedPlugsData(selectedOptions);
    setSelectedPlugs(selectedOptions.map((option: any) => option.value));
  };

  const onBrandChange = (selectedOption: any) => {
    setSelectedBrandData(selectedOption);
    setSelectedBrand(selectedOption ? selectedOption.value : null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const initialValues: any = {
    name: "",
    image: "",
    order: 1,
    isActive: true,
    efficiency: 1,
    range: 1,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    efficiency: Yup.number().required("Please enter efficiency"),
    range: Yup.number().required("Please enter range"),
    order: Yup.number().required("Please add vehicle order"),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const params = {
        values,
        isActive,
        image: brandImage
          ? formatImage({ file: brandImage, source: "imageKit" })
          : null,
        brand: selectedBrand,
        plugs: selectedPlugs,
      };

      if (!selectedBrand) {
        setAlert({
          active: true,
          type: "error",
          message: "Please add brand",
        });
        return;
      }
      if (!selectedPlugs || selectedPlugs?.length === 0) {
        setAlert({
          active: true,
          type: "error",
          message: "Please add plug type",
        });
        return;
      }
      if (id) {
        await updateVehicle({ id, ...params });
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Vehicle Updated successfully",
        });
      } else {
        await addVehicle(params);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Vehicle added successfully",
        });
      }
      setTimeout(() => {
        navigate("/admin/all-vehicles");
      }, 2000);
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: " Error while adding Vehicle",
      });
    }
  };

  const onError = () => {
    setAlert({
      active: true,
      type: "error",
      message: " Error while uploading image",
    });
    setIsUploading(false);
  };
  const onSuccess = (res: any) => {
    setBrandImage(res);
    setIsUploading(false);
  };
  const onUploadProgress = () => {
    setIsUploading(true);
  };

  const handleDeleteFile = () => {
    setBrandImage(null);
  };

  const onDelete = async (deleteId: any) => {
    try {
      await DeleteVehicle({ id: deleteId });
      refetch();
      setAlert({
        active: true,
        type: "success",
        message: "Successfully Deleted",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "success",
        message: "Failed to delete",
      });
    }
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
    onError,
    onSuccess,
    onUploadProgress,
    isUploading,
    brandImage,
    loading,
    setLoading,
    fileInputRef,
    isActive,
    setIsActive,
    handleDeleteFile,
    setAlert,
    setSelectedPlugs,
    setSelectedBrand,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    id,
    isSuccess,
    vehicleData,
    setBrandImage,
    onDelete,
    selectedPlugsData,
    plugOptions,
    onChange,
    selectedBrandData,
    brandOptions,
    onBrandChange,
    deleteId,
    setDeleteId,
    isVisible,
    setIsVisible,
  };
};
