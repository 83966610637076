/*  */
/* Amenity services */
/*  */

import { app, getDBInstance, ObjectId } from "./dbServices";

export const addAmenity = async ({ values, isActive, image }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
  
    if (!values) {
      return;
    }
    const result = await getDBInstance()
      .collection("amenities")
      .insertOne({
        name: {
          en: values.name,
        },
        isActive,
        image: image ? image : {},
        dateCreated: new Date(),
        dateModified: null,
        order: values.order,
        capacity: values.capacity,
      });
    return result;
  };
  
  export const updateAmenity = async ({ id, values, isActive, image }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (!id || !values) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("amenities")
      .updateOne(
        {
          _id: id,
        },
        {
          $set: {
            name: {
              en: values.name,
            },
            isActive,
            image: image ? image : {},
            dateModified: new Date(),
            order: values.order,
            capacity: values.capacity,
          },
        }
      );
  
    return result;
  };
  
  export const DeleteAmenity = async ({ id }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("amenities")
      .deleteOne({ _id: id });
  
    return result;
  };
  
  export const getAmenities = async ({ page = 0, rowsPerPage = 10 }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    const skip = page * rowsPerPage;
    const limit = rowsPerPage;
  
    const totalDocumentCount = await getDBInstance()
      .collection("amenities")
      .count({});
  
    const result = await getDBInstance()
      .collection("amenities")
      .aggregate([
        {
          $match: {},
        },
        {
          $sort: {
            order: 1,
          },
        },
        { $skip: skip },
        { $limit: limit },
        {
          $project: {
            _id: 1,
            name: 1,
            isActive: 1,
            dateCreated: 1,
            dateModified: 1,
            order: 1,
            capacity: 1,
            image: {
              url: 1,
              name: 1,
            },
          },
        },
      ]);
  
    return { data: result, totalDocumentCount };
  };
  
  export const getOneAmenity = async ({ id }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (!id) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("amenities")
      .aggregate([
        {
          $match: { _id: id },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ]);
  
    return result[0];
  };