import { DeleteCms } from "controller/services/CMSServices";
import React, { useContext } from "react";
import { AlertContext } from "controller/context/alertContext";
import { useChargingHistories } from "model/queryCalls/queryCalls";

export const useHistory = () => {
  const { setAlert } = useContext(AlertContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { refetch } = useChargingHistories({ page, rowsPerPage });
  const [isVisible, setIsVisible] = React.useState(false);

  const [deleteId, setDeleteId] = React.useState();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const onDelete = async (deleteId: any) => {
    try {
      await DeleteCms({ id: deleteId });
      refetch();
      setAlert({
        active: true,
        type: "success",
        message: "Successfully Deleted",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "success",
        message: "Failed to delete",
      });
    }
  };

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    deleteId,
    setDeleteId,
    isVisible,
    setIsVisible,
  };
};
