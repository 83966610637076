import React from "react";
import { Box, Skeleton } from "@mui/material";
import Card from "components/card";
import { useOneStation } from "model/queryCalls/queryCalls";

import { BsDatabaseFillX } from "react-icons/bs";
import { MdArrowCircleLeft, MdEditSquare, MdEvStation } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const BasicDetails = ({ id }: any) => {
  const navigate = useNavigate();
  const { data: stationData, status } = useOneStation({ id });

  return (
    <div className="col-span-5 my-2 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
      <Card extra={"w-full h-full p-3"}>
        {status === "loading" ? (
          <Box>
            <Skeleton sx={{ height: 190 }} />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        ) : status === "success" ? (
          <div className="relative mb-3 items-center justify-between py-5 text-center lg:px-10">
            <div className="mb-auto flex flex-col items-center justify-center">
              <div className="flex w-full flex-row items-center justify-between">
                <MdArrowCircleLeft
                  className="h-8 w-8 text-navy-700 hover:cursor-pointer hover:text-navy-500 dark:text-white hover:dark:text-gray-600"
                  onClick={() => navigate(-1)}
                />
                <MdEditSquare
                  className="h-8 w-8 text-navy-700 hover:cursor-pointer hover:text-navy-500 dark:text-white hover:dark:text-gray-600"
                  onClick={() =>
                    navigate(`/admin/edit-station/${stationData._id}`)
                  }
                />
              </div>
              <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[20px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                {stationData?.image && stationData?.image.url ? (
                  <img
                    src={stationData?.image.url}
                    alt={stationData?.name}
                    className="h-[75px] w-[75px]"
                  />
                ) : (
                  <MdEvStation />
                )}
              </div>
              <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                {stationData?.name}
              </h4>

              <p className="text-base font-normal text-gray-600">
                {stationData?.networkName.value}
              </p>
              {/* <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
          Supervise your drive space in the easiest way
        </p> */}
            </div>
            <Card extra={"w-full h-full p-3 mt-6"}>
              {/* Cards */}
              <div className="mt-3 grid grid-cols-1 gap-4 px-2 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Network</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {stationData?.networkName.value}
                  </p>
                </div>

                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Current Status</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {!stationData?.currentStatus?.value
                      ? `Not Available`
                      : stationData?.currentStatus?.value}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Access Type</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {stationData?.accessType}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Facility Type</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {stationData?.facilityType.value}
                  </p>
                </div>

                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Phone</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {!stationData.phone ? `Not Available` : stationData.phone}
                  </p>
                </div>

                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Address</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {stationData?.address?.streetAddress} ,{" "}
                    {stationData?.address?.city}, {stationData?.address?.state},{" "}
                    {stationData?.address?.country}
                  </p>
                </div>

                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Hours of Operation</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {!stationData?.hoursOfOperation
                      ? `Not Available`
                      : stationData?.hoursOfOperation}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Amenities</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {stationData?.amenitiesName &&
                      stationData?.amenitiesName.map(
                        (amenity: any, index: any) => (
                          <span>
                            {index !== stationData.amenitiesName.length - 1
                              ? amenity.en + ", "
                              : amenity.en}
                          </span>
                        )
                      )}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Plugs</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {stationData?.plugsName &&
                      stationData?.plugsName.map((plug: any, index: any) => (
                        <span>
                          {index !== stationData.plugsName.length - 1
                            ? plug.en + ", "
                            : plug.en}
                        </span>
                      ))}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">
                    Number of Level 1 ports
                  </p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {!stationData?.noOfLevel1Ports
                      ? 0
                      : stationData?.noOfLevel1Ports}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">
                    Number of Level 2 ports
                  </p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {!stationData?.noOfLevel2Ports
                      ? 0
                      : stationData?.noOfLevel2Ports}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">
                    Number of DC Fast ports
                  </p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {!stationData?.noOfDcFastPorts
                      ? 0
                      : stationData?.noOfDcFastPorts}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Email</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {!stationData?.email ? "Not Available" : stationData?.email}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">
                    {`Average Cost (per hour)`}
                  </p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {!stationData?.avgCost
                      ? "Not Available"
                      : stationData?.avgCost + "$"}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">
                    Accepted Payment Methods
                  </p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {stationData?.acceptedPaymentMethods?.value ? (
                      stationData?.acceptedPaymentMethods.value.map(
                        (card: any, index: any) => (
                          <span>
                            {index !==
                            stationData?.acceptedPaymentMethods?.value?.length -
                              1
                              ? card + ", "
                              : card}
                          </span>
                        )
                      )
                    ) : (
                      <p className="text-base font-medium text-navy-700 dark:text-white">
                        Not Available
                      </p>
                    )}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Access Detail Info</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {!stationData?.accessDetailCode?.value
                      ? `Not Available`
                      : stationData?.accessDetailCode?.value}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Maximum Vehicle Size</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {!stationData?.maximumVehicleSize
                      ? `Not Available`
                      : stationData?.maximumVehicleSize}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Owner Type</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {!stationData?.ownerType.value
                      ? `Not Available`
                      : stationData?.ownerType.value}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">
                    Renewable Energy Source
                  </p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {!stationData?.renewableEnergySource.value
                      ? `Not Available`
                      : stationData?.renewableEnergySource.value}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Access Restricted</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {!stationData?.restrictedAccess
                      ? `Not Available`
                      : stationData?.restrictedAccess}
                  </p>
                </div>
              </div>
            </Card>
          </div>
        ) : (
          <div className="flex w-full flex-col items-center justify-center">
            <BsDatabaseFillX className="h-16 w-16 text-gray-500" />
            <p className="mt-3 text-sm font-bold text-gray-800">No Data</p>
          </div>
        )}
      </Card>
    </div>
  );
};

export default BasicDetails;
