import { Box, Chip, Skeleton, TablePagination } from "@mui/material";
import Card from "components/card";
import { useVehicle } from "model/queryCalls/queryCalls";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useVehicles } from "./useVehicle";
import { BsDatabaseFillX, BsFileEarmarkImage } from "react-icons/bs";
import ModalContainer from "components/modal";

const Vehicles = () => {
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    deleteId,
    setDeleteId,
    isVisible,
    setIsVisible,
  } = useVehicles();
  const navigate = useNavigate();
  const { data: vehiclesData, status } = useVehicle({ page, rowsPerPage });

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <Card extra={"w-full h-full p-3"}>
          <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
            {status === "loading" ? (
              <Box>
                <Skeleton sx={{ height: 190 }} />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Box>
            ) : status === "success" && vehiclesData?.data?.length !== 0 ? (
              <>
                <Table className="w-full min-w-full table-auto">
                  <Thead>
                    <Tr>
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Order
                        </p>
                      </Th>
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Image
                        </p>
                      </Th>
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Name
                        </p>
                      </Th>
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Brand
                        </p>
                      </Th>
                      {/* <Th className="border-gray-100 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Plugs
                        </p>
                      </Th> */}
                      {/* <Th className="border-gray-100 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Efficiency
                        </p>
                      </Th>
                      <Th className="border-gray-100 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Range
                        </p>
                      </Th> */}
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Is Active
                        </p>
                      </Th>
                      <Th className="border-b border-gray-100 px-5 py-3 text-center">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Actions
                        </p>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {vehiclesData.data.map((data: any) => (
                      <Tr
                        key={data._id}
                        className=" hover:cursor-pointer hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20"
                        onClick={() =>
                          navigate(`/admin/vehicle-details/${data._id}`)
                        }
                      >
                        <Td className="border-b border-gray-100 px-5 py-3">
                          <p className="text-left font-semibold">
                            {data.order}
                          </p>
                        </Td>
                        <Td className="border-b border-gray-100  px-5 py-3">
                          {data?.image?.url ? (
                            <img
                              src={data?.image.url}
                              alt={data?.name.en}
                              className="h-[50px] w-[50px]"
                            />
                          ) : (
                            <BsFileEarmarkImage className="h-[40px] w-[40px] text-gray-800" />
                          )}
                        </Td>
                        <Td className="border-b border-gray-100  px-5 py-3">
                          <p className="font-semibold">{data.name.en}</p>
                        </Td>
                        <Td className="border-b border-gray-100  px-5 py-3">
                          <p className="font-semibold">{data.brand.en}</p>
                        </Td>
                        {/* <Td className="border-gray-100 border-b  px-5 py-3">
                            <p className="font-semibold">
                              {data?.plugs &&
                                data?.plugs.map((plug: any, index: any) => (
                                  <span>
                                    {index !== data.plugs.length - 1
                                      ? plug.en + ", "
                                      : plug.en}
                                  </span>
                                ))}
                            </p>
                          </Td> */}
                        {/* <Td className="border-gray-100 border-b  px-5 py-3">
                            <p className="font-semibold">
                              {data.efficiency}kWh/100mi
                            </p>
                          </Td>
                          <Td className="border-gray-100 border-b  px-5 py-3">
                            <p className="font-semibold">{data.range}mi</p>
                          </Td> */}
                        <Td className="border-b border-gray-100  px-5 py-3">
                          <p>
                            {data.isActive ? (
                              <Chip
                                label="Active"
                                color="success"
                                size="small"
                              />
                            ) : (
                              <Chip
                                label="Inactive"
                                color="error"
                                size="small"
                              />
                            )}
                          </p>
                        </Td>
                        <Td className="border-b border-gray-100  px-5 py-3 text-center">
                          <div className="flex items-center gap-2 xl:justify-center">
                            <button
                              onClick={(
                                event: React.MouseEvent<
                                  HTMLButtonElement,
                                  MouseEvent
                                >
                              ) => {
                                event.stopPropagation();
                                navigate(`/admin/edit-vehicle/${data._id}`);
                              }}
                              className="z-50 text-blue-500 hover:underline"
                            >
                              <MdEdit className="h-5 w-5" />
                            </button>
                            <button
                              onClick={(
                                event: React.MouseEvent<
                                  HTMLButtonElement,
                                  MouseEvent
                                >
                              ) => {
                                event.stopPropagation();
                                setIsVisible(!isVisible);
                                setDeleteId(data?._id);
                              }}
                              className="z-50 text-red-500 hover:underline"
                            >
                              <MdDelete className="h-5 w-5" />
                            </button>
                          </div>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  className="text-sm font-bold text-navy-700 dark:text-white"
                  count={vehiclesData?.totalDocumentCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <div className="flex w-full flex-col items-center justify-center">
                <BsDatabaseFillX className="h-16 w-16 text-gray-500" />
                <p className="mt-3 text-sm font-bold text-gray-800">No Data</p>
              </div>
            )}
          </div>
        </Card>
      </div>
      <ModalContainer
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        deleteId={deleteId}
        confirm={onDelete}
      />
    </div>
  );
};

export default Vehicles;
