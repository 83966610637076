import { Box, Tab, Tabs } from "@mui/material";
import React from "react";

import { useParams } from "react-router-dom";
import ChargingHistory from "../chargingHistory";
import ComingSoon from "components/comingSoon";
import BasicDetails from "./basicDetails";
import { useChargingStations } from "./useChargingStations";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

const StationDetails = () => {
  const { id } = useParams();
  const { handleChange, a11yProps, value } = useChargingStations();

  return (
    <div className="flex w-full flex-col gap-5">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Basic Details" {...a11yProps(0)} />
        <Tab label="Charging History" {...a11yProps(1)} />
        <Tab label="Reviews" {...a11yProps(2)} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <BasicDetails id={id} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ChargingHistory stationId={id} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ComingSoon />
      </CustomTabPanel>
    </div>
  );
};

export default StationDetails;
