/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  MenuItemStyles,
  menuClasses,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";

import routes from "routes";
import {
  MdHome,
  MdPerson,
  MdElectricalServices,
  MdElectricCar,
  MdEvStation,
  MdRestaurant,
  MdContentPaste,
  MdConstruction,
  MdAdsClick,
  MdNotificationsActive,
  MdPeople,
} from "react-icons/md";
import React from "react";
import { BsBootstrapFill } from "react-icons/bs";

const SidebarContainer = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
  toggled: boolean;
  setToggled: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose, toggled, setToggled } = props;
  const menuItemStyles: MenuItemStyles = {
    root: {
      // fontSize: "13px",
      // fontWeight: 400,
    },
    // icon: {
    //   color: themes[theme].menu.icon,
    //   [`&.${menuClasses.disabled}`]: {
    //     color: themes[theme].menu.disabled.color,
    //   },
    // },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({}),
    button: {
      [`&.${menuClasses.disabled}`]: {
        // color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        // backgroundColor: hexToRgba(
        //   themes[theme].menu.hover.backgroundColor,
        //   hasImage ? 0.8 : 1
        // ),
        // color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };
  const [openSubMenu, setOpenSubMenu] = React.useState(null);

  const handleSubMenuClick = (menuKey: string) => {
    if (openSubMenu === menuKey) {
      setOpenSubMenu(null); // Close the submenu if it's already open
    } else {
      setOpenSubMenu(menuKey); // Open the new submenu
    }
  };

  return (
    // <div
    //   className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:relative xl:!z-50 ${
    //     open ? "translate-x-0" : "-translate-x-96"
    //   }`}
    // >
    //   <span
    //     className="absolute right-4 top-4 block cursor-pointer xl:hidden"
    //     onClick={onClose}
    //   >
    //     <HiX />
    //   </span>

    //   <div className={`mx-[56px] mt-[50px] flex items-center`}>
    //     <div className="ml-1 mt-1 h-2.5 font-poppins text-[22px] font-bold uppercase text-navy-700 dark:text-white">
    //       Charge
    //       <span className="font-medium">Meter</span>
    //     </div>
    //   </div>
    //   <div className="mb-7 mt-[58px] h-px bg-gray-300 dark:bg-white/30" />
    //   {/* Nav item */}

    //   <ul className="mb-auto pt-1">
    //     <Links routes={routes} />
    //   </ul>
    // </div>
    <Sidebar
      collapsed={open}
      toggled={toggled}
      // onBackdropClick={() => setToggled(false)}
      // onBreakPoint={setBroken}
      // image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
      // rtl={rtl}
      breakPoint="md"
      // backgroundColor={}
      // className="bg-white dark:!bg-navy-800 dark:text-white"
      // rootStyles={{
      //   color: themes[theme].sidebar.color,
      // }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
        className="bg-white dark:!bg-navy-800 dark:text-white"
      >
        <div className={`mx-[20px] mb-[50px] mt-[30px] flex items-center`}>
          <span
            className="absolute right-4 top-4 block cursor-pointer md:hidden"
            onClick={setToggled}
          >
            <HiX />
          </span>
          {!open && (
            <div className="ml-1 mt-1 h-2.5 font-poppins text-[22px] font-bold uppercase text-navy-700 dark:text-white">
              Charge
              <span className="font-medium"> Meter</span>
            </div>
          )}
        </div>
        <div style={{ flex: 1, marginBottom: "32px" }}>
          {!open && (
            <div style={{ padding: "0 24px", marginBottom: "8px" }}>
              <div
                className={`h-2.6 ml-1 mt-1 font-poppins text-[12px] font-bold text-navy-700 dark:text-white ${
                  open ? "opacity-0" : "opacity-1"
                }`}
              >
                General
              </div>
            </div>
          )}
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              icon={<MdHome className="h-6 w-6" />}
              className="font-medium hover:bg-navy-200 hover:dark:text-brand-500"
              component={<Link to="/admin/default" />}
              onClick={() => handleSubMenuClick(null)}
            >
              Dashboard
            </MenuItem>
          </Menu>
          {!open && (
            <div style={{ padding: "0 24px", marginBottom: "8px" }}>
              <div
                className={`h-2.6 ml-1 mt-1 font-poppins text-[12px] font-bold text-navy-700 dark:text-white ${
                  open ? "opacity-0" : "opacity-1"
                }`}
              >
                Manage Vehicles
              </div>
            </div>
          )}
          <Menu menuItemStyles={menuItemStyles}>
            <SubMenu
              label="Brands"
              icon={<BsBootstrapFill className="h-6 w-6" />}
              className="hover:dark:text-brand-500"
              open={openSubMenu === "brands"}
              onClick={() => handleSubMenuClick("brands")}
            >
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/all-brands" />}
              >
                All Brands
              </MenuItem>
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/add-brand" />}
              >
                Add Brand
              </MenuItem>
            </SubMenu>
            <SubMenu
              label="Vehicles"
              icon={<MdElectricCar className="h-6 w-6" />}
              className="hover:dark:text-brand-500"
              open={openSubMenu === "vehicles"}
              onClick={() => handleSubMenuClick("vehicles")}
            >
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/all-vehicles" />}
              >
                All Vehicles
              </MenuItem>
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/add-vehicle" />}
              >
                Add Vehicle
              </MenuItem>
            </SubMenu>
            <SubMenu
              label="Plug Types"
              icon={<MdElectricalServices className="h-6 w-6" />}
              className="hover:dark:text-brand-500"
              open={openSubMenu === "plugs"}
              onClick={() => handleSubMenuClick("plugs")}
            >
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/all-plugs" />}
              >
                All Plug Types
              </MenuItem>
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/add-plug" />}
              >
                Add Plug
              </MenuItem>
            </SubMenu>
          </Menu>

          {!open && (
            <div style={{ padding: "0 24px", marginBottom: "8px" }}>
              <div
                className={`h-2.6 ml-1 mt-1 font-poppins text-[12px] font-bold text-navy-700 dark:text-white ${
                  open ? "opacity-0" : "opacity-1"
                }`}
              >
                Manage Charging Stations
              </div>
            </div>
          )}
          <Menu menuItemStyles={menuItemStyles}>
            <SubMenu
              label="Charging Stations"
              icon={<MdEvStation className="h-6 w-6" />}
              className="hover:dark:text-brand-500"
              open={openSubMenu === "stations"}
              onClick={() => handleSubMenuClick("stations")}
            >
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/all-stations" />}
              >
                All Stations
              </MenuItem>
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/charging-history" />}
              >
                Charging History
              </MenuItem>
            </SubMenu>
            <SubMenu
              label="Amenities"
              icon={<MdRestaurant className="h-6 w-6" />}
              className="hover:dark:text-brand-500"
              open={openSubMenu === "amenities"}
              onClick={() => handleSubMenuClick("amenities")}
            >
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/all-amenities" />}
              >
                All Amenities
              </MenuItem>
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/add-amenity" />}
              >
                Add Amenity
              </MenuItem>
            </SubMenu>
          </Menu>

          {!open && (
            <div style={{ padding: "0 24px", marginBottom: "8px" }}>
              <div
                className={`h-2.6 ml-1 mt-1 font-poppins text-[12px] font-bold text-navy-700 dark:text-white ${
                  open ? "opacity-0" : "opacity-1"
                }`}
              >
                Manage Users
              </div>
            </div>
          )}
          <Menu menuItemStyles={menuItemStyles}>
            <SubMenu
              label="Admin"
              icon={<MdPerson className="h-6 w-6" />}
              className="hover:dark:text-brand-500"
              open={openSubMenu === "admin"}
              onClick={() => handleSubMenuClick("admin")}
            >
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/all-users" />}
              >
                All Users
              </MenuItem>
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/add-users" />}
              >
                Add User
              </MenuItem>
              {/* <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/coming-soon" />}
              >
                All Roles
              </MenuItem>
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/add-roles" />}
              >
                Add Roles
              </MenuItem> */}
            </SubMenu>
            <MenuItem
              icon={<MdPeople className="h-6 w-6" />}
              className="font-medium hover:bg-navy-200 hover:dark:text-brand-500"
              component={<Link to="/admin/all-customers" />}
              onClick={() => handleSubMenuClick(null)}
            >
              Customers
            </MenuItem>
          </Menu>

          {!open && (
            <div style={{ padding: "0 24px", marginBottom: "8px" }}>
              <div
                className={`h-2.6 ml-1 mt-1 font-poppins text-[12px] font-bold text-navy-700 dark:text-white ${
                  open ? "opacity-0" : "opacity-1"
                }`}
              >
                App Settings
              </div>
            </div>
          )}
          <Menu menuItemStyles={menuItemStyles}>
            <SubMenu
              label="CMS"
              icon={<MdContentPaste className="h-6 w-6" />}
              className="hover:dark:text-brand-500"
              open={openSubMenu === "cms"}
              onClick={() => handleSubMenuClick("cms")}
            >
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/all-cms" />}
              >
                All CMS Pages
              </MenuItem>
              <MenuItem
                className="font-medium dark:text-brand-500"
                component={<Link to="/admin/add-cms" />}
              >
                Add CMS Page
              </MenuItem>
              <MenuItem
                className="font-medium hover:bg-navy-200 hover:dark:text-brand-500"
                component={<Link to="/admin/popular-routes" />}
              >
                Popular Routes
              </MenuItem>
              <MenuItem
                className="font-medium hover:bg-navy-200 hover:dark:text-brand-500"
                component={<Link to="/admin/add-popular-route" />}
              >
                Add Popular Route
              </MenuItem>
              <MenuItem
                className="font-medium hover:bg-navy-200 hover:dark:text-brand-500"
                component={<Link to="/admin/all-faqs" />}
              >
                All FAQs
              </MenuItem>
              <MenuItem
                className="font-medium hover:bg-navy-200 hover:dark:text-brand-500"
                component={<Link to="/admin/add-faq" />}
              >
                Add FAQ
              </MenuItem>
            </SubMenu>
            <MenuItem
              icon={<MdConstruction className="h-6 w-6" />}
              className="font-medium hover:bg-navy-200 hover:dark:text-brand-500"
              component={<Link to="/admin/maintenance-mode" />}
              onClick={() => handleSubMenuClick(null)}
            >
              Maintenance Mode
            </MenuItem>
            <MenuItem
              icon={<MdAdsClick className="h-6 w-6" />}
              className="font-medium hover:bg-navy-200 hover:dark:text-brand-500"
              component={<Link to="/admin/coming-soon" />}
              onClick={() => handleSubMenuClick(null)}
            >
              Advertisement
            </MenuItem>

            <MenuItem
              icon={<MdNotificationsActive className="h-6 w-6" />}
              className="font-medium hover:bg-navy-200 hover:dark:text-brand-500"
              component={<Link to="/admin/coming-soon" />}
              onClick={() => handleSubMenuClick(null)}
            >
              Push Notification
            </MenuItem>
          </Menu>

          <div
            style={{
              padding: "0 24px",
              marginBottom: "8px",
              marginTop: "32px",
            }}
          >
            {/* <Typography
              variant="body2"
              fontWeight={600}
              style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
            >
              Extra
            </Typography> */}
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default SidebarContainer;
