/*  */
/* chargingHistory services */
/*  */

import { app, getDBInstance, ObjectId } from "./dbServices";

export const getChargingHistory = async ({
    page = 0,
    rowsPerPage = 10,
    stationId = null,
    customerId = null,
  }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    // const page = parseInt(query.page, 10) || 1;
    // const perPage = parseInt(query.perPage, 10) || 10; // Default to 10 if not specified
    const skip = page * rowsPerPage;
    const limit = rowsPerPage;
  
    const pipeline = [];
  
    if (stationId) {
      if (typeof stationId === "string") {
        stationId = new ObjectId(stationId);
      }
  
      pipeline.push({
        $match: {
          stationId: stationId,
        },
      });
    }
  
    if (customerId) {
      if (typeof customerId === "string") {
        customerId = new ObjectId(customerId);
      }
  
      pipeline.push({
        $match: {
          customerId: customerId,
        },
      });
    }
  
    // Lookup to customer collection
    pipeline.push({
      $lookup: {
        from: "customers",
        localField: "customerId",
        foreignField: "_id",
        as: "customerData",
      },
    });
  
    // Unwind customerData array
    pipeline.push({
      $unwind: "$customerData",
    });
  
    // Lookup to chargingStation collection
    pipeline.push({
      $lookup: {
        from: "evstations",
        localField: "stationId",
        foreignField: "_id",
        as: "stationData",
      },
    });
  
    // Unwind stationData array
    pipeline.push({
      $unwind: "$stationData",
    });
  
    // Lookup to vehicles collection
    pipeline.push({
      $lookup: {
        from: "vehicles",
        localField: "vehicleId",
        foreignField: "_id",
        as: "vehicleData",
      },
    });
  
    // Unwind vehicleData array
    pipeline.push({
      $unwind: "$vehicleData",
    });
  
    // Project the desired fields
    pipeline.push({
      $project: {
        _id: 1,
        checkedInAt: 1,
        ableToCharge: 1,
        status: 1,
        comment: 1,
        customerData: {
          _id: "$customerData._id",
          name: "$customerData.name",
        },
        stationData: {
          _id: "$stationData._id",
          name: "$stationData.name",
        },
        vehicleData: {
          _id: "$vehicleData._id",
          name: "$vehicleData.name",
        },
      },
    });
  
    //skip the fields
  
    pipeline.push({
      $skip: skip,
    });
  
    //limit the fields
  
    pipeline.push({
      $limit: limit,
    });
  
    const totalDocumentCount = await getDBInstance()
      .collection("chargingHistory")
      .count({});
  
    const result = await getDBInstance()
      .collection("chargingHistory")
      .aggregate(pipeline);
    // .toArray();
    return { data: result, totalDocumentCount };
  };
  
  export const getOneChargingHistory = async ({ id }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (!id) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("chargingHistory")
      .aggregate([
        {
          $match: { _id: id },
        },
        {
          $lookup: {
            from: "customers",
            localField: "customerId",
            foreignField: "_id",
            as: "customerData",
          },
        },
        {
          $unwind: "$customerData",
        },
        {
          $lookup: {
            from: "evstations",
            localField: "stationId",
            foreignField: "_id",
            as: "stationData",
          },
        },
        {
          $unwind: "$stationData",
        },
        {
          $lookup: {
            from: "vehicles",
            localField: "vehicleId",
            foreignField: "_id",
            as: "vehicleData",
          },
        },
        {
          $unwind: "$vehicleData",
        },
        {
          $project: {
            _id: 1,
            checkedInAt: 1,
            ableToCharge: 1,
            status: 1,
            comment: 1,
            customerData: {
              _id: "$customerData._id",
              name: "$customerData.name",
            },
            stationData: {
              _id: "$stationData._id",
              name: "$stationData.name",
            },
            vehicleData: {
              _id: "$vehicleData._id",
              name: "$vehicleData.name",
            },
          },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ]);
  
    return result[0];
  };