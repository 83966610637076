/*  */
/* Charging Station services */
/*  */

import { app, getDBInstance, ObjectId } from "./dbServices";

export const addEVStation = async ({ values, image, amenities }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
  
    if (!values) {
      return;
    }
    const result = await getDBInstance().collection("evstations").insertOne({
      image,
      amenities,
      avgCost: values.cost,
      email: values.email,
      dateCreated: new Date(),
      dateModified: null,
    });
    return result;
  };
  
  export const updateEVStation = async ({
    id,
    image,
    amenities,
    values,
  }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (!id) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("evstations")
      .updateOne(
        {
          _id: id,
        },
        {
          $set: {
            image,
            amenities,
            avgCost: values.cost,
            email: values.email,
            dateModified: new Date(),
          },
        }
      );
  
    return result;
  };
  
  export const deleteStation = async ({ id }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("evstations")
      .deleteOne({ _id: id });
  
    return result;
  };
  
  export const getEVStations = async ({ page = 0, rowsPerPage = 10 }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    // const page = parseInt(query.page, 10) || 1;
    // const perPage = parseInt(query.perPage, 10) || 10; // Default to 10 if not specified
    const skip = page * rowsPerPage;
    const limit = rowsPerPage;
  
    const totalDocumentCount = await getDBInstance()
      .collection("evstations")
      .count({});
  
    const result = await getDBInstance()
      .collection("evstations")
      .aggregate([
        {
          $match: {},
        },
        { $skip: skip },
        { $limit: limit },
      ]);
    // .toArray();
    return { data: result, totalDocumentCount };
  };
  
  export const getOneEVStation = async ({ id }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (!id) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("evstations")
      .aggregate([
        {
          $match: { _id: id },
        },
        {
          $lookup: {
            from: "plugTypes",
            localField: "evConnectorTypeIds",
            foreignField: "_id",
            as: "plugDetails",
          },
        },
        {
          $lookup: {
            from: "amenities",
            localField: "amenities",
            foreignField: "_id",
            as: "amenityDetails",
          },
        },
        {
          $project: {
            _id: 1,
            remoteId: 1,
            acceptedPaymentMethods: 1,
            accessDetailCode: 1,
            accessType: 1,
            address: 1,
            currentStatus: 1,
            dateLastConfirmed: 1,
            expectedDate: 1,
            facilityType: 1,
            geocodeStatus: 1,
            hoursOfOperation: 1,
            maximumVehicleSize: 1,
            name: 1,
            networkName: 1,
            noOfDcFastPorts: 1,
            noOfLevel1Ports: 1,
            noOfLevel2Ports: 1,
            openDate: 1,
            ownerType: 1,
            phone: 1,
            pricing: 1,
            renewableEnergySource: 1,
            restrictedAccess: 1,
            updatedAt: 1,
            dateModified: 1,
            amenities: 1,
            evConnectorTypeIds: 1,
            image: {
              url: 1,
              name: 1,
            },
            amenitiesName: "$amenityDetails.name",
            plugsName: "$plugDetails.name",
            email: 1,
            avgCost: 1,
          },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ]);
  
    return result[0];
  };