/*  */
/* Vehicle services */
/*  */

import { app, getDBInstance, ObjectId } from "./dbServices";

export const addVehicle = async ({
    values,
    isActive,
    image,
    brand,
    plugs,
  }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
  
    if (!values) {
      return;
    }
    const result = await getDBInstance()
      .collection("vehicles")
      .insertOne({
        name: {
          en: values.name,
        },
        isActive,
        image: image ? image : {},
        dateCreated: new Date(),
        dateModified: null,
        order: values.order,
        brand: brand,
        plugs: plugs,
        efficiency: values.efficiency,
        range: values.range,
      });
    return result;
  };
  
  export const updateVehicle = async ({
    id,
    values,
    isActive,
    image,
    brand,
    plugs,
  }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (!id || !values) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("vehicles")
      .updateOne(
        {
          _id: id,
        },
        {
          $set: {
            name: {
              en: values.name,
            },
            isActive,
            image: image ? image : {},
            dateModified: new Date(),
            order: values.order,
            brand: brand,
            plugs: plugs,
            efficiency: values.efficiency,
            range: values.range,
          },
        }
      );
  
    return result;
  };
  
  export const DeleteVehicle = async ({ id }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("vehicles")
      .deleteOne({ _id: id });
  
    return result;
  };
  
  export const getOneVehicle = async ({ id }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (!id) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("vehicles")
      .aggregate([
        {
          $match: { _id: id },
        },
        {
          $lookup: {
            from: "vehicleBrands",
            localField: "brand",
            foreignField: "_id",
            as: "brandDetails",
          },
        },
        {
          $unwind: "$brandDetails",
        },
        {
          $lookup: {
            from: "plugTypes",
            localField: "plugs",
            foreignField: "_id",
            as: "plugDetails",
          },
        },
        {
          $project: {
            _id: 1,
            name: 1,
            isActive: 1,
            dateCreated: 1,
            dateModified: 1,
            order: 1,
            image: {
              url: 1,
              name: 1,
            },
            brand: 1,
            plugs: 1,
            brandName: "$brandDetails.name",
            plugsName: "$plugDetails.name",
            efficiency: 1,
            range: 1,
          },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ]);
    return result[0];
  };
  
  export const getVehicles = async ({ page = 0, rowsPerPage = 10 }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    const skip = page * rowsPerPage;
    const limit = rowsPerPage;
  
    const totalDocumentCount = await getDBInstance()
      .collection("vehicles")
      .count({});
  
    const result = await getDBInstance()
      .collection("vehicles")
      .aggregate([
        {
          $match: {},
        },
        {
          $lookup: {
            from: "vehicleBrands",
            localField: "brand",
            foreignField: "_id",
            as: "brandDetails",
          },
        },
        {
          $unwind: "$brandDetails",
        },
        {
          $lookup: {
            from: "plugTypes",
            localField: "plugs",
            foreignField: "_id",
            as: "plugDetails",
          },
        },
        {
          $project: {
            _id: 1,
            name: 1,
            isActive: 1,
            dateCreated: 1,
            dateModified: 1,
            order: 1,
            image: {
              url: 1,
              name: 1,
            },
            brand: "$brandDetails.name",
            plugs: "$plugDetails.name",
            efficiency: 1,
            range: 1,
          },
        },
        { $skip: skip },
        { $limit: limit },
        {
          $sort: {
            order: 1,
          },
        },
      ]);
  
    return { data: result, totalDocumentCount };
  };