/*  */
/* Plug services */
/*  */

import { app, getDBInstance, ObjectId } from "./dbServices";

export const addPlug = async ({ values, isActive, image, type }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
  
    if (!values) {
      return;
    }
    const result = await getDBInstance()
      .collection("plugTypes")
      .insertOne({
        name: {
          en: values.name,
        },
        isActive,
        image: image ? image : {},
        dateCreated: new Date(),
        dateModified: null,
        order: values.order,
        powerOutput: values.powerOutput,
        type,
      });
    return result;
  };
  
  export const updatePlug = async ({
    id,
    values,
    isActive,
    image,
    type,
  }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (!id || !values) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("plugTypes")
      .updateOne(
        {
          _id: id,
        },
        {
          $set: {
            name: {
              en: values.name,
            },
            isActive,
            image: image ? image : {},
            dateModified: new Date(),
            order: values.order,
            powerOutput: values.powerOutput,
            type,
          },
        }
      );
  
    return result;
  };
  
  export const DeletePlug = async ({ id }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("plugTypes")
      .deleteOne({ _id: id });
  
    return result;
  };
  
  export const getPlugs = async ({ page, rowsPerPage }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    const skip = page * rowsPerPage;
    const limit = rowsPerPage;
  
    const totalDocumentCount = await getDBInstance()
      .collection("plugTypes")
      .count({});
  
    const result = await getDBInstance()
      .collection("plugTypes")
      .aggregate([
        {
          $match: {},
        },
        {
          $sort: {
            order: 1,
          },
        },
        { $skip: skip },
        { $limit: limit },
        {
          $project: {
            _id: 1,
            name: 1,
            isActive: 1,
            dateCreated: 1,
            dateModified: 1,
            order: 1,
            type: 1,
            powerOutput: 1,
            image: {
              url: 1,
              name: 1,
            },
          },
        },
      ]);
  
    return { data: result, totalDocumentCount };
  };
  
  export const getOnePlug = async ({ id }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (!id) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("plugTypes")
      .aggregate([
        {
          $match: { _id: id },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ]);
  
    return result[0];
  };